import { useState, useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const dev = false

function PersonsSelector({age, gender, quantity, onAdd, onRemove}) {
  return (
    <div style={{
      borderRadius: '.5em'
    }} className="bg-darkened p-3">
      <h5 className="text-center mb-3">{age}<br />{gender}</h5>
      <p className="text-center">
      <Button onClick={onRemove} style={{
        visibility: quantity > 0 ? 'visible' : 'hidden',
      }}>&nbsp;<strong>-</strong>&nbsp;</Button>
      &nbsp; &nbsp;<strong>{quantity}</strong>&nbsp; &nbsp;
      <Button onClick={onAdd} style={{
        
      }}>&nbsp;<strong>+</strong>&nbsp;</Button>
      </p>
    </div>
  )
}

function App() {
  const [priceCategory, setPriceCategory] = useState(4)
  const [figAdultMale, setFigAdultMale] = useState(0)
  const [figAdultFemale, setFigAdultFemale] = useState(0)
  const [figChildMale, setFigChildMale] = useState(0)
  const [figChildFemale, setFigChildFemale] = useState(0)
  const [shopStatus, setShopStatus] = useState({alive: null, delay: null})
  const [showShopOffModal, setShowShopOffModal] = useState(false)
  const [showShopBusyModal, setShowShopBusyModal] = useState(false)
  const [showShopAvailableModal, setShowShopAvailableModal] = useState(false)
  const checkShopTimer = useRef(null)


  const forwardToShop = ({force = false}) => {
    console.log('forward', shopStatus, shopStatus.delay, shopStatus.delay, !force)
    if (figAdultMale === 0 && figAdultFemale === 0 && figChildMale === 0 && figChildFemale === 0)
      alert('Bitte wählen Sie mindestens eine Figur aus.')
    else if (shopStatus.delay && shopStatus.delay > 1 && !force) {
      setShowShopBusyModal(true)
    }
      
    else
      window.location.href = 'https://shop.miniatur-wunderland.de/spendenkonzert-helene-fischer.html?'
        + 'pc=' + priceCategory
        + '&tam=' + figAdultMale
        + '&taf=' + figAdultFemale
        + '&tcm=' + figChildMale
        + '&tcf=' + figChildFemale

  }

  const checkShopStatus = () => {
    fetch('/shopstatus.json')
      .then(response => response.json())
      .then(data => {
        const now = Date.now()
        console.log('shopStatus:', data, 'last check:', parseInt(now.toString().substr(0, 10)) - data.timestamp, 'seconds ago')

        if (parseInt(now.toString().substr(0, 10)) - data.timestamp < 60 || dev) {
          console.log('shopStatus: up to date')
          if (shopStatus.alive == 0 && data.shopAlive == 1) {
            setShowShopOffModal(false)
            setShowShopBusyModal(false)
            setShowShopAvailableModal(true)
          }

          setShopStatus({alive: data.shopAlive == 1, delay: data.delay})
          if (data.shopAlive == 0 && checkShopTimer.current == null) {
            checkShopTimer.current = setInterval(checkShopStatus, 10000)
          } else if (checkShopTimer.current !== null) {
            clearInterval(checkShopTimer.current)
            checkShopTimer.current = null
          }
            
        } else {
          console.log('shopStatus: Information is outdated, update requested. Waiting for result...')

          const xhrSet = new XMLHttpRequest()
          xhrSet.open('GET', 'setshopstatus.php', true)
          xhrSet.send()
          setTimeout(checkShopStatus, 5000)
        }
      });
  }

  useEffect(() => {
    checkShopStatus()
  }, [])

  useEffect(() => {
    if (shopStatus.alive == 0) {
      setShowShopOffModal(true)
    }
  }, [shopStatus])

  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <Row>
            <Col className="pt-3 text-left" md={4} sm={12}>
              <Image src="https://s-img.s3-eu-west-1.amazonaws.com/r6xm0aro6znpgmsy.png" width={200} className="App-logo" alt="logo" />
            </Col>
            <Col md={8} sm={12} className="pt-md-3 pt-sm-5">
              <h4 className="mt-4 mb-sm-5 mb-md-2">Das Helene-Fischer-Benefizkonzert im Miniatur Wunderland </h4>
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          <Row className="mt-2 mt-md-4 mt-lg-5 mb-5 mx-0">
            <Col className="pt-0 pt-lg-5 px-0 px-md-1 px-lg-2 pb-4 pb-lg-0">
              <h1 className="display-4 text-primary mt-3 mt-md-4 mt-lg-5 px-0 mx-0">Eine kleine Spende für eine große Sache.</h1>
            </Col>
            <Col md={3} sm={10} className="text-center pt-3 pt-md-0 pt-lg-0 px-md-0 px-sm-5">
              <div className="px-md-3 px-sm-5 px-xs-5">
              <Image fluid src="https://s-img.s3-eu-west-1.amazonaws.com/cklq7p82xjklicd1.png" alt="Helene" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="mx-auto">
          <Row className="bg-darkened">
            <Col>
              <Container className="py-lg-3 py-md-1 py-sm-0">
                <Row className="my-3">
                  <Col sm={12} md={6} lg={4} className="pt-2 pb-2 px-lg-4 px-md-3 px-sm-0">
                    <Image fluid rounded src="https://s-img.s3-eu-west-1.amazonaws.com/ib9rpo56678jqm81.jpg"  />
                  </Col>
                  <Col sm={12} md={6} lg={8} className=" px-lg-2 px-md-3 px-sm-0">
                    <p>Seit fünf Jahren sammeln Helene Fischer und das Miniatur Wunderland gemeinsam Geld für Kinder in Not. Insgesamt wurden schon über 560.000 Euro gespendet. Dafür bauten die Modellbauer der Hamburger Miniaturwelt Helenes Farbenspiel-Konzert im Olympiastadion Berlin im Maßstab 1:87 komplett nach - einschließlich aufwändiger Bühnenshow.</p>
                    <p>Doch es ist mehr als eine spektakuläre Miniatur - es ist ein Benefizkonzert, klein gebaut, aber groß gedacht. Für jedes verkaufte „Ticket“ wird eine Mini-Figur vom Miniatur Wunderland gestiftet und in das Stadion gesetzt. Der Preis für das Ticket geht zu 100% als Spende direkt weiter an „Ein Herz für Kinder“. Bisher haben sich knapp 40.000 Fans mit einer kleinen Figur verewigt und damit Kindern in Not geholfen.</p>
                    <p><a href="#infos">> So funktionert es</a></p>
                  </Col>
                </Row>
        
              </Container>

            </Col>
          </Row>
        </Container>
        <Container fluid className="mx-auto mt-5 mb-5">
          <Row className="bg-highlighted">
            <Col>
        <Container>
          <Row className="mb-4">
            <Col>
              <h2 className="text-center mt-5 text-white">
                <span className="text-primary">1.</span> Wählen Sie Ihre Plätze:
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={12} className="p-2">
              <Button style={{ 
                backgroundImage: 'url(https://s-img.s3-eu-west-1.amazonaws.com/0f94g51ng1pmzfpc.jpg)',
                border: priceCategory === 4 ? '3px solid #ffffff' : '1px solid rgb(232, 62, 140)',
                backgroundPosition: ''
              }} 
              className="text-center priceCategoryButton"
              onClick={() => setPriceCategory(4)}>
                <strong>Ränge</strong><br />10 €
              </Button>
            </Col>
            <Col md={4} sm={12} className="p-2">
              <Button 
                style={{ 
                  backgroundImage: 'url(https://s-img.s3-eu-west-1.amazonaws.com/4ybkasaexjcede73.jpg)',
                  border: priceCategory === 3 ? '4px solid #ffffff' : '1px solid rgb(232, 62, 140)',
                }} 
                className="text-center priceCategoryButton"
                onClick={() => setPriceCategory(3)}
                >
                <strong>Innenfeld</strong><br />25 €
              </Button>
            </Col>
            <Col md={4} sm={12} className="p-2">
              <Button style={{ 
                backgroundImage: 'url(https://s-img.s3-eu-west-1.amazonaws.com/mvbdvz5cvs9xe2hr.jpg)',
                border: priceCategory === 1 ? '4px solid #ffffff' : '1px solid rgb(232, 62, 140)',
              }} 
              className="text-center priceCategoryButton"
              onClick={() => setPriceCategory(1)}>
                <strong>Ehrentribüne</strong><br />100 €
              </Button>
            </Col>
          </Row>
          <Row className="mt-5 mb-4">
            <Col>
              <h2 className="text-center mt-md-3 mt-lg-5 text-white">
              <span className="text-primary">2.</span> Wählen Sie Ihre Figuren:
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6} sm={12} className="p-2">
              <PersonsSelector
                age="Erwachsener"
                gender="weiblich"
                quantity={figAdultFemale}
                onAdd={() => setFigAdultFemale(figAdultFemale + 1)}
                onRemove={() => setFigAdultFemale(figAdultFemale - 1)}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className="p-2">
              <PersonsSelector
                age="Erwachsener"
                gender="männlich"
                quantity={figAdultMale}
                onAdd={() => setFigAdultMale(figAdultMale + 1)}
                onRemove={() => setFigAdultMale(figAdultMale - 1)}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className="p-2">
              <PersonsSelector
                age="Kind"
                gender="weiblich"
                quantity={figChildFemale}
                onAdd={() => setFigChildFemale(figChildFemale + 1)}
                onRemove={() => setFigChildFemale(figChildFemale - 1)}
              />
            </Col>
            <Col lg={3} md={6} sm={12} className="p-2">
              <PersonsSelector
                age="Kind"
                gender="männlich"
                quantity={figChildMale}
                onAdd={() => setFigChildMale(figChildMale + 1)}
                onRemove={() => setFigChildMale(figChildMale - 1)}
              />
            </Col>
            
          </Row>
          <Row className="mt-5 mb-4">
            <Col>
              <h2 className="text-center mt-md-3 mt-lg-5 text-white">
              <span className="text-primary">3.</span> Bestellung abschließen:
              </h2>
            </Col>
          </Row>
          <Row className="mt-5 mb-3">
            <Col className="text-center"> 
              <a name="infos"><Button size="lg" onClick={() => forwardToShop(false)} className="mb-3">Weiter</Button></a><br />
              <small>Sie werden ggf. mehrfach weitergeleitet, bitte haben Sie einen Moment Geduld.</small>
              </Col>
          </Row>
        </Container>
        </Col></Row></Container>
        <Container fluid className="mx-auto">
          <Row className="bg-darkened">
            <Col>
              <Container className="py-lg-3 py-md-1 py-sm-0">
                
                <Row className="">
                  <Col>
                    <h1 className="mt-3 mb-5 text-center text-secondary">So funktioniert es:</h1>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={8} lg={9} className="px-lg-2 px-md-1 px-sm-0">
                    <p>Wählen Sie oben aus, wo im Stadion Sie Figuren platzieren lassen möchten und mit wie vielen Begleitern.</p>
                    <p>Hier die Preise pro Benefizticket:</p>
                    <Container>
                      <Row className="mb-3">
                        <Col md={12} lg={4} className="pl-md-2 pl-lg-1">Rang: 10 €</Col>
                        <Col md={12} lg={4} className="pl-md-2 pl-lg-1">Innenfeld: 25 €</Col>
                        <Col md={12} lg={4} className="pl-md-2 pl-lg-1">Ehrentribüne: 100 €</Col>
                      </Row>
                      
                    </Container>
                    <p>Nachdem Sie ein Ticket gebucht haben, setzen die Wunderländer Modellbauer symbolisch eine Figur in das Stadion. Zusätzlich erhalten Sie im Laufe der kommenden Wochen per Email eine Dankesurkunde. Der bezahlte Betrag für die „Tickets“ wird anschließend zu 100 % an „Ein Herz für Kinder“ weitergeleitet.</p>
                    <p>Wenn Sie das Miniatur Wunderland in Hamburg besuchen, wird im Konzert eine Figur sitzen, die Sie gestiftet haben!</p>
                  </Col>
                  <Col sm={12} md={4} lg={3} className="pt-2 px-lg-2 px-md-3 px-sm-0">
                    <Image fluid rounded src="https://s-img.s3-eu-west-1.amazonaws.com/k83avy2n39hcdcy3.jpg"  />
                  </Col>
                </Row>
              </Container>

            </Col>
          </Row>
          </Container>
          </main>
          <footer>
          <Container className="mt-5 pt-2" fluid style={{backgroundColor: 'rgba(0, 0, 0, .9)'}}>
            <Row><Col>
          <Container>
          <Row className="justify-content-md-center">
            <Col md={'auto'} sm={12} className="text-center">
              <a className="text-white" href="https://shop.miniatur-wunderland.de/contacts/"><small>Kontakt</small></a>
            </Col>
            <Col md={'auto'} sm={12} className="text-center">
              <a className="text-white" href="https://shop.miniatur-wunderland.de/impressum/"><small>Impressum</small></a>
            </Col>
            <Col md={'auto'} sm={12} className="text-center">
              <a className="text-white" href="https://shop.miniatur-wunderland.de/agb/"><small>AGB</small></a>
            </Col>
            <Col md={'auto'} sm={12} className="text-center">
              <a className="text-white" href="https://shop.miniatur-wunderland.de/widerrufsrecht/"><small>Widerrufsrecht</small></a>
            </Col>
            <Col md={'auto'} sm={12} className="text-center">
              <a className="text-white" href="https://shop.miniatur-wunderland.de/datenschutz/"><small>Datenschutz</small></a>
            </Col>
          </Row>
          <Row className="mt-2 mb-3 justify-content-md-center">
            <Col className="text-center">
              <a className="text-white" href="https://www.miniatur-wunderland.de"><small>&copy; 2020 Miniatur Wunderland GmbH</small></a>
            </Col>
          </Row>
          
        </Container>
        </Col></Row>

          </Container>
        </footer>
        <Modal 
          show={showShopBusyModal}
          onHide={() => {
            setShowShopBusyModal(false)
          }}
          >
          <Modal.Title className="p-3 bg-warning text-black">Warnung</Modal.Title>
          <Modal.Body>
            <p>Im Moment kann es durch starken Andrang zu Problemen in unserem Online-Shop kommen.</p>
            <p>Falls möglich, versuchen Sie es bitte zu einem späteren Zeitpunkt noch einmal.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {
            setShowShopBusyModal(false)
            }}>&nbsp; Ok &nbsp;</Button>
            <Button variant="secondary" onClick={() => forwardToShop(true)}>Weiter zum Shop</Button>
          </Modal.Footer>
        </Modal>
        <Modal 
          show={showShopOffModal}
          onHide={() => {
            setShowShopOffModal(false)
          }}
          >
          <Modal.Title className="p-3 bg-gradient-danger text-white">Fehler</Modal.Title>
          <Modal.Body>
            <p>Unser Shop ist durch starken Andrang momentan leider nicht mehr erreichbar.</p>
            <p>Bitte versuchen Sie es zu einem späteren Zeitpunkt oder morgen noch einmal.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {
            setShowShopBusyModal(false)
            }}>&nbsp; Ok &nbsp;</Button>
          </Modal.Footer>
        </Modal>
        <Modal 
          show={showShopAvailableModal}
          onHide={() => {
            setShowShopAvailableModal(false)
          }}
          >
          <Modal.Title className="p-3 bg-gradient-success text-white">Hinweis</Modal.Title>
          <Modal.Body>
            <p>Unser Shop ist wieder erreichbar. Sie können mit Ihrer Bestellung fortfahren.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {
            setShowShopAvailableModal(false)
            }}>&nbsp; Ok &nbsp;</Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}

export default App;
